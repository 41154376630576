<template>
  <BaseModal
    useForm
    :heading="
      $t('global.actions.search_item', { item: $t('global.items.giftcard', 1) })
    "
    small
    @submit="handleSubmit"
    @close="emit('close')"
  >
    <div v-if="!selectedCard" :class="$style.search">
      <BaseSearch
        v-model="code"
        :placeholder="
          $t('global.actions.search_item', {
            item: $t('global.items.giftcard', 1)
          })
        "
        focus
        v-test="'register-giftcard-search'"
      />
      <BaseFloatingList
        v-if="code && !!giftcards?.length"
        ref="list"
        :data="giftcards"
        @select="selectedCard = $event"
        v-test="'register-giftcard-results'"
      />
    </div>
    <div v-else>
      <div :class="$style.row">
        <div :class="$style.column">
          <BaseHeading size="s" :mb="0.5">
            {{ $t('global.number') }}
          </BaseHeading>
          <BaseText bold v-test="'register-giftcard-code'">
            {{ selectedCard.code }}
          </BaseText>
        </div>

        <div :class="$style.column">
          <BaseHeading size="s" :mb="0.5">
            {{ $t('global.remaining') }}
          </BaseHeading>
          <BaseText bold v-test="'register-giftcard-remaining'">
            {{ filters.currency(selectedCard.remainingAmount) }}
          </BaseText>
        </div>

        <div :class="$style.column">
          <BaseHeading size="s" :mb="0.5">
            {{ $t('register.amount_due') }}
          </BaseHeading>
          <BaseText bold v-test="'register-giftcard-due'">
            {{ filters.currency(outstandingAmount) }}
          </BaseText>
        </div>

        <div :class="$style.column">
          <BaseHeading size="s" :mb="0.5">
            {{ $t('global.status') }}
          </BaseHeading>
          <BaseLabel
            :state="selectedCard.status"
            v-test="'register-giftcard-status'"
          />
        </div>

        <BaseButton
          icon="close"
          color="inverted"
          @click="selectedCard = null"
          v-test="'register-giftcard-remove'"
        />
      </div>

      <BaseInput
        v-model="amount"
        mt
        type="currency"
        :label="$t('global.amount_currency')"
        required
        :minValue="1"
        :maxValue="Math.min(selectedCard.remainingAmount, outstandingAmount)"
        v-test="'register-giftcard-amount'"
      />
    </div>
    <BaseText v-if="isAlreadyUsed" mt color="error">
      {{ $t('giftcard.already_used') }}
    </BaseText>
    <BaseText
      v-if="code && !loading && !giftcards?.length"
      mt
      color="error"
      v-test="'register-giftcard-none'"
    >
      {{ $t('global.no_results') }}
    </BaseText>

    <template #footer>
      <BaseButton
        color="inverted"
        @click="emit('close')"
        v-test="'register-giftcard-close'"
      >
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton
        submitForm
        :disabled="!selectedCard || !!(selectedCard && isAlreadyUsed)"
        v-test="'register-giftcard-save'"
      >
        {{ $t('global.actions.submit') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { storeToRefs } from 'pinia';
import type { Giftcard } from '@/types';
import { ref, computed, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { GET_GIFTCARDS } from './graphql';
import filters from '@/filters';

const props = defineProps<{
  paymentAmount: number;
}>();

const emit = defineEmits(['submit', 'close']);

const { order } = useRegisterOrderStore();
const { outstandingAmount } = storeToRefs(useRegisterOrderStore());

const selectedCard = ref<Giftcard | null>();
const amount = ref(Math.min(props.paymentAmount, outstandingAmount.value));
const code = ref('');

const isAlreadyUsed = computed(
  () =>
    selectedCard.value &&
    !!order.transactions.find(
      (transaction) =>
        !transaction.id && transaction.giftcardId === selectedCard.value?.id
    )
);

watch(selectedCard, () => {
  if (selectedCard.value) {
    amount.value = Math.min(
      props.paymentAmount,
      selectedCard.value.remainingAmount
    );
  }
});

const trimmedCode = computed(() => code.value.replace(/\W/g, ''));
const { result, loading } = useQuery(
  GET_GIFTCARDS,
  { search: trimmedCode, filterScope: 'USABLE' },
  () => ({ debounce: 300, enabled: !!code.value })
);

const giftcards = computed(() =>
  result.value?.giftcards.map((giftcard: Giftcard) => ({
    ...giftcard,
    status: giftcard.status.toLowerCase(),
    tableData: {
      code: filters.giftCardCode(giftcard.code),
      amount: filters.currency(giftcard.remainingAmount),
      status: giftcard.status.toLowerCase()
    }
  }))
);

const handleSubmit = () => {
  if (selectedCard.value) {
    emit('submit', {
      type: 'giftcard',
      amount: amount.value,
      id: selectedCard.value.id,
      name: selectedCard.value.code
    });
  }
};
</script>

<style lang="scss" module>
.search {
  position: relative;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
</style>
