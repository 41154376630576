<template>
  <div
    ref="el"
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.mediumScreen]: $screen === 'm',
        [$style.isProduct]: item.type === 'product'
      }
    ]"
    :style="
      service
        ? {
            'border-left-color': service.category.color,
            top: y + 'px',
            left: x + 'px'
          }
        : {
            top: y + 'px',
            left: x + 'px'
          }
    "
    @click="addBrowserItem"
    v-test="'register-browser-favorite'"
  >
    <div :class="$style.top">
      <div :class="$style.icon">
        <div
          :class="$style.iconInner"
          @click="unfavorite($event)"
          v-test="'register-browser-favorite-unfavorite'"
        >
          <BaseIcon name="star-filled" color="primary" size="s" />
        </div>
      </div>
      <div :class="$style.heading">
        <BaseHeading size="xs">
          {{
            item.type === 'service'
              ? $t('global.items.service', 1)
              : $t('global.items.product', 1)
          }}
        </BaseHeading>
      </div>
      <transition name="pop-small">
        <div v-if="addedAmount" :class="$style.quantity">
          {{ addedAmount }}
        </div>
      </transition>
    </div>
    <div :class="$style.name">
      {{ item.label }}
    </div>
    <div :class="$style.bottom">
      <BaseText size="s">
        {{ filters.currency(item.price) }}
      </BaseText>
    </div>
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import { useServicesStore } from '@/stores/services';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { useRegisterBrowserStore } from '@/modules/register/builder/browser/store';
import { storeToRefs } from 'pinia';
import { watchArray } from '@vueuse/core';

const props = defineProps<{
  item: any;
}>();

const { addItem, order } = useRegisterOrderStore();

const addBrowserItem = () => {
  addItem({
    name: props.item.label || props.item.name,
    medical: props.item.medical,
    price: props.item.price,
    serviceId: props.item.type === 'service' ? props.item.id : undefined,
    productId: props.item.type === 'product' ? props.item.id : undefined
  });
};

const addedAmount = computed(() => {
  const existingItem = order.items.find((item) =>
    props.item.type === 'service'
      ? item.serviceId === props.item.id
      : item.productId === props.item.id
  );
  return existingItem?.quantity || 0;
});

const unfavorite = (e: any) => {
  e.stopPropagation();

  const { removeFavorite } = useRegisterBrowserStore();
  removeFavorite(props.item);
};

const service = computed(() => {
  if (props.item.type !== 'service') {
    return;
  }

  const { serviceById } = useServicesStore();
  return serviceById(props.item.id);
});

const { favorites } = storeToRefs(useRegisterBrowserStore());
const el = ref();
const x = ref(0);
const y = ref(0);

const setPosition = () => {
  if (!el.value) {
    return;
  }

  x.value = el.value.offsetLeft;
  y.value = el.value.offsetTop;
};

onMounted(() => setPosition());

watchArray(favorites, () => {
  nextTick(() => setPosition());
});
</script>

<style lang="scss" module>
.base {
  padding: 10px;
  background-color: white;
  border-radius: $radius;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  border-left-width: 3px;
  border-left-style: solid;
  transition: transform 0.15s $easeOutBack;
  cursor: pointer;

  &.mediumScreen {
    width: calc(50% - 5px);
  }

  &.smallScreen {
    width: 100%;
  }

  &:not(.smallScreen):not(.mediumScreen) {
    width: calc(33.33% - 6px);
  }

  &.isProduct {
    border-left-color: $color-primary;
  }

  &:active {
    transform: scale(0.97);
  }
}

.top {
  display: flex;
  align-items: center;
  gap: $spacing * 0.5;
  margin-bottom: 4px;
}

.heading {
  overflow: hidden;
}

.name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  margin-bottom: $spacing * 0.25;
  height: 32px;
}

.quantity {
  margin: -4px -4px -4px auto;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-primary-light;
  border-radius: 50%;
  font-size: 12px;
  flex-shrink: 0;
}

.bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: $spacing * 0.5;
}

.iconInner {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin: -6px;
  @include background-hover;
}
</style>
