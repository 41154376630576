<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <div :class="$style.nav">
      <Nav :navItems="navItems" v-test="'settings-nav'" />
    </div>
    <div :class="$style.content" v-test="'admin-scroll-container'">
      <div :class="$style.contentInner">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Nav from './Nav.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SettingsBase',
  components: {
    Nav
  },
  props: {
    navItems: {
      type: Object
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  height: 100%;
  border-top: 1px solid $color-border;
  background-color: white;
}

$navWidth: 200px;

.nav {
  .base:not(.smallScreen) & {
    width: $navWidth;
    height: 100%;
    border-right: 1px solid $color-border;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .base.smallScreen & {
    position: absolute;
    top: $header-height;
    left: 0;
    width: 100%;
    z-index: 5;
    background-color: white;
    overflow: hidden;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  }
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $color-body-bg;

  .base:not(.smallScreen) & {
    height: 100%;
    width: calc(100% - #{$navWidth});
    overflow-y: auto;
  }

  .base.smallScreen & {
    width: 100%;
    padding-top: 52px; // Mobile subnav height
    z-index: 1;
  }
}

.contentInner {
  height: 100%;
}
</style>
