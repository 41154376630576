<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <AddSubstractLoyaltyPoints
      v-if="showModal"
      @close="showModal = false"
      @submit="onChangeLoyaltyPoints"
    />
    <div v-if="!showEmptyPageContent">
      <div v-if="firstFetchFinished" :class="$style.header">
        <BaseHeading v-test="'totalLoyaltyPoints'">
          {{
            totalPoints === 1
              ? $t('customers.total_loyalty_point', { amount: totalPoints })
              : $t('customers.total_loyalty_points', { amount: totalPoints })
          }}
        </BaseHeading>
        <BaseButton @click="showModal = true" v-test="'AddLoyaltyPoints'">
          {{ $t('global.actions.add_or_sub_loyalty_points') }}
        </BaseButton>
      </div>
      <BaseTable
        v-if="
          loyaltyTransactions.loyaltyTransactions &&
          loyaltyTransactions.loyaltyTransactions.length
        "
        :headers="
          [
            $t('global.date'),
            $t('global.amount'),
            $t('global.description')
          ].filter((header) => !!header)
        "
        :rows="
          loyaltyTransactions.loyaltyTransactions.map(
            (loyaltyTransaction: any) => ({
              id: loyaltyTransaction.id,
              cells: [
                {
                  value: filters.dateTime(loyaltyTransaction.transactionAt),
                  type: 'date'
                },
                {
                  value:
                    loyaltyTransaction.amount > 0
                      ? `+${loyaltyTransaction.amount}`
                      : loyaltyTransaction.amount,
                  color: loyaltyTransaction.amount > 0 ? 'success' : 'error'
                },
                loyaltyTransaction.orderNumber
                  ? loyaltyTransaction.orderNumber
                  : loyaltyTransaction.description
              ],
              actions: getActions(loyaltyTransaction)
            })
          )
        "
        @action="handleAction"
        v-test="'user-loyalty-transactions'"
      />
      <LoadMore
        v-show="!allDataFetched && firstFetchFinished"
        @shown="fetchMore"
      />
      <BaseSpinner v-show="!firstFetchFinished" inline mt />
      <BaseText
        v-if="
          allDataFetched &&
          !loading &&
          (!loyaltyTransactions.loyaltyTransactions ||
            !loyaltyTransactions.loyaltyTransactions.length)
        "
        center
        mt
      >
        {{ $t('global.no_results') }}
      </BaseText>
    </div>
    <EmptyPageContent
      v-show="showEmptyPageContent"
      imageName="loyalty-points"
      :text="{
        title: $t('customers.empty_state.loyalty_points.title'),
        description: $t('customers.empty_state.loyalty_points.description'),
        btnPrimary: $t('global.actions.add_or_sub_loyalty_points')
      }"
      :moreLink="{
        text: $t('customers.empty_state.loyalty_points.more_text'),
        url: $t('customers.empty_state.loyalty_points.more_url')
      }"
      @click="onEmptyPageClick"
      v-test="'loyalty-points-noresults'"
    />
  </div>
</template>

<script lang="ts">
export default {
  name: 'CustomerLoyaltyPoints'
};
</script>

<script lang="ts" setup>
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import AddSubstractLoyaltyPoints from './AddSubstractLoyaltyPoints.vue';
import LoadMore from '@/components/LoadMore.vue';
import filters from '@/filters';
import dayjs from '@/dayjs';
import config from '@/config';

import { computed, inject, ref } from 'vue';
import { usePagination } from '@/apollo/pagination';
import {
  GET_LOYALTY_TRANSACTIONS,
  CREATE_LOYALTY_TRANSACTION,
  DELETE_LOYALTY_TRANSACTION
} from './graphql';
import { useUserStore } from '@/stores/user';
import { useMutation } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const { hasFeatureFlag } = useUserStore();

const showModal = ref(false);

const { mutate: createLoyaltyPoints } = useMutation(CREATE_LOYALTY_TRANSACTION);

const onChangeLoyaltyPoints = (variables: any) => {
  createLoyaltyPoints({
    input: {
      amount: variables.amount,
      description: variables.description,
      customerId: customer.value.id,
      transactionAt: dayjs().format()
    }
  }).then(() => {
    showModal.value = false;
    refetch();
  });
};

const { mutate: deleteLoyaltyTransaction } = useMutation(
  DELETE_LOYALTY_TRANSACTION
);

const customer = inject<any>('customer');
const {
  loyaltyTransactions,
  allDataFetched,
  loading,
  firstFetchFinished,
  fetchMore,
  refetch
} = usePagination({
  fieldName: 'loyaltyTransactions',
  query: GET_LOYALTY_TRANSACTIONS,
  variables: {
    customerId: customer.value.id
  },
  options: {
    subFieldName: 'loyaltyTransactions'
  }
});

const totalPoints = computed(
  () => loyaltyTransactions.value?.metadata?.total_points || 0
);

const getOrderId = (id: string) => {
  const loyaltyTransaction =
    loyaltyTransactions.value?.loyaltyTransactions.find(
      (loyaltyTransaction: any) => loyaltyTransaction.id === id
    );

  if (!loyaltyTransaction) {
    return null;
  }
  return loyaltyTransaction.orderId;
};

const handleAction = ({
  id,
  option
}: {
  id: string;
  option: 'download' | 'delete';
}) => {
  const orderId = getOrderId(id);
  switch (option) {
    case 'download':
      if (orderId) {
        window.open(`${config.backendUrl}/orders/${orderId}.pdf`);
      }
      break;
    case 'delete':
      deleteLoyaltyTransaction({
        input: {
          id: parseInt(id)
        }
      }).then(() => refetch());
      break;
  }
};

const getActions = (loyaltyTransaction: any) => {
  const actions = [];
  if (loyaltyTransaction.orderId) {
    actions.push({
      icon: 'download',
      name: 'download',
      label: t('global.actions.download_item', { item: t('global.order') })
    });
  }

  if (hasFeatureFlag('loyalty-points-delete')) {
    actions.push({
      name: 'delete'
    });
  }

  return actions;
};

const showEmptyPageContent = computed(
  () =>
    !loading.value &&
    (!loyaltyTransactions.value.loyaltyTransactions ||
      !loyaltyTransactions.value.loyaltyTransactions.length)
);
const onEmptyPageClick = (button: string) => {
  if (button === 'primary') {
    showModal.value = true;
  }
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: $spacing * 0.5;
  margin-bottom: $spacing * -1;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;

  .base.smallScreen & {
    justify-content: flex-end;
  }

  & > * {
    margin-bottom: $spacing;

    .base.smallScreen & {
      margin-left: $spacing;
    }
  }
}
</style>
