<template>
  <div>
    <BaseInputLabel :text="$t('promote.booking_widget.settings.color')" />
    <BaseColorLabel
      v-model="formData.settings.bookingWidget.customColor"
      colorPicker
      selected
      v-test="'giftcard-setting-color'"
    />
    <BaseCard mt gray>
      <BaseInputLabel :text="$t('giftcard_widget.settings.picture.heading')" />
      <div :class="$style.selectedVisual">
        <img
          :src="
            formData.settings.sales.giftcardPicture
              ? formData.settings.sales.giftcardPicture
              : 'https://res.cloudinary.com/salonized-eu-cld/image/upload/v1730893591/giftcard-picture/defaults/gc_default.jpg'
          "
          :class="$style.selectedVisualImage"
        />
        <div>
          <BaseText>
            <span v-html="$t('giftcard_widget.visual_instruction')" />
          </BaseText>

          <BaseButton
            v-if="formData.settings.sales.giftcardPicture"
            color="inverted"
            mt
            @click="formData.settings.sales.giftcardPicture = ''"
          >
            {{ $t('global.actions.delete') }}
          </BaseButton>
        </div>
      </div>
      <div :class="$style.visuals">
        <div
          :class="[
            $style.visual,
            {
              [$style.selected]: !formData.settings.sales.giftcardPicture
            }
          ]"
          @click="formData.settings.sales.giftcardPicture = ''"
        >
          <div
            v-if="!formData.settings.sales.giftcardPicture"
            :class="$style.visualCheck"
          >
            <BaseIcon name="check" color="inverted" size="s" />
          </div>
          <img
            src="https://res.cloudinary.com/salonized-eu-cld/image/upload/v1730893591/giftcard-picture/defaults/gc_default.jpg"
          />
        </div>
        <div
          v-for="image in defaultImages"
          :key="image"
          :class="[
            $style.visual,
            {
              [$style.selected]:
                formData.settings.sales.giftcardPicture === image
            }
          ]"
          @click="formData.settings.sales.giftcardPicture = image"
        >
          <div
            v-if="formData.settings.sales.giftcardPicture === image"
            :class="$style.visualCheck"
          >
            <BaseIcon name="check" color="inverted" size="s" />
          </div>
          <img :src="image" />
        </div>
        <div :class="$style.uploadHolder">
          <BaseHeading size="s">{{ $t('global.or') }}</BaseHeading>
          <BaseUpload
            preset="giftcard-picture"
            :image="formData.settings.sales.giftcardPicture"
            text="Upload picture"
            @uploadResults="
              formData.settings.sales.giftcardPicture = $event.secure_url
            "
          />
        </div>
      </div>
    </BaseCard>
  </div>
</template>
<script setup lang="ts">
const formData = inject<any>('giftcardSettingsData');

const defaultImages = [
  'https://res.cloudinary.com/salonized-eu-cld/image/upload/v1730893591/giftcard-picture/defaults/gc_visual_2.jpg',
  'https://res.cloudinary.com/salonized-eu-cld/image/upload/v1730893591/giftcard-picture/defaults/gc_visual_3.jpg',
  'https://res.cloudinary.com/salonized-eu-cld/image/upload/v1730893591/giftcard-picture/defaults/gc_visual_4.jpg',
  'https://res.cloudinary.com/salonized-eu-cld/image/upload/v1730893591/giftcard-picture/defaults/gc_visual_5.jpg',
  'https://res.cloudinary.com/salonized-eu-cld/image/upload/v1730893591/giftcard-picture/defaults/gc_visual_6.jpg',
  'https://res.cloudinary.com/salonized-eu-cld/image/upload/v1730893591/giftcard-picture/defaults/gc_visual_7.jpg',
  'https://res.cloudinary.com/salonized-eu-cld/image/upload/v1730893591/giftcard-picture/defaults/gc_visual_8.jpg'
];
</script>

<style lang="scss" module>
.selectedVisual {
  display: flex;
  align-items: center;
  margin-bottom: $spacing;
  gap: $spacing;
}

.selectedVisualImage {
  width: 150.4px;
  height: 94px;
}

.visuals {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: $spacing 0.5 * $spacing;
  flex-wrap: wrap;

  &::after {
    content: '';
    flex: auto;
  }
}

.visual {
  position: relative;
  border-radius: $radius;
  width: 116px;
  height: 72px;

  cursor: pointer;
  &.selected {
    box-shadow: 0 0 0 2px $color-primary;
  }
  img {
    border-radius: $radius;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

.uploadHolder {
  display: flex;
  padding-left: $spacing;
  gap: 1.5 * $spacing;
  align-items: center;
}

.visualCheck {
  position: absolute;
  left: 6px;
  top: 6px;
  width: 16px;
  height: 16px;
  background-color: $color-primary;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    opacity 0.2s $easeOutBack,
    transform 0.2s $easeOutBack;

  .visual:not(.selected) & {
    opacity: 0;
    transform: scale(0.5);
  }
}
</style>
