<template>
  <BaseCard gray>
    <BaseInputLabel :text="$t('giftcard_widget.settings.amounts.heading')" />
    <div :class="$style.amounts">
      <div
        v-for="(amount, index) in formData.settings.sales.giftcardValues"
        :key="`amount-${index}`"
        :class="$style.amount"
      >
        <BaseInput
          v-model="formData.settings.sales.giftcardValues[index]"
          :minValue="500"
          :maxValue="100000"
          type="currency"
          v-test="'giftcard-setting-amount'"
        />
        <BaseIcon
          v-if="formData.settings.sales.giftcardValues.length > 1"
          name="close"
          :ml="0.25"
          clickable
          @click="formData.settings.sales.giftcardValues.splice(index, 1)"
          v-test="'giftcard-setting-amount-remove'"
        />
      </div>
    </div>
    <BaseText
      iconBefore="plus"
      link
      inline
      mt
      @click="addAmount"
      v-test="'giftcard-setting-amount-add'"
    >
      {{ $t('giftcard_widget.settings.amounts.add') }}
    </BaseText>
    <BaseCheckbox
      v-model="formData.settings.sales.giftcardCustomAmount"
      mt
      :label="$t('giftcard_widget.settings.amounts.custom')"
      v-test="'giftcard-custom-amount'"
    />
    <div
      v-if="formData.settings.sales.giftcardCustomAmount"
      :class="$style.customAmounts"
    >
      <BaseInput
        v-model="formData.settings.sales.giftcardCustomAmountMin"
        :label="$t('giftcard_widget.settings.amounts.custom_minimum')"
        :minValue="500"
        :maxValue="95000"
        type="currency"
        v-test="'giftcard-custom-amount-min'"
      />
      <BaseInput
        v-model="formData.settings.sales.giftcardCustomAmountMax"
        :label="$t('giftcard_widget.settings.amounts.custom_maximum')"
        type="currency"
        :minValue="1000"
        :maxValue="100000"
        v-test="'giftcard-custom-amount-max'"
      />
    </div>
  </BaseCard>
</template>

<script setup lang="ts">
const formData = inject<any>('giftcardSettingsData');

const addAmount = () => {
  const highestAmount = Math.max(
    ...formData.settings.sales.giftcardValues,
    500
  );
  formData.settings.sales.giftcardValues.push(highestAmount + 500);
};
</script>

<style lang="scss" module>
.amounts {
  display: flex;
  gap: $spacing;
  flex-wrap: wrap;
}

.amount {
  display: flex;
  align-items: center;
}

.customAmounts {
  margin-top: $spacing;
  display: flex;
  gap: $spacing;
}
</style>
