<template>
  <ModuleBase noBorder gray :loading="loading">
    <PageWrap :heading="$t('promote.nav.links.pricing_widget')">
      <BaseText :mb="2">
        {{ $t('admin.pricing_widget.description') }}
      </BaseText>
      <BaseCard :mb="2" :heading="$t('global.settings')">
        <BaseForm
          @submit="
            mutate({
              input: {
                baseColor: color
              }
            })
          "
        >
          <div :class="$style.inputs">
            <BaseInput
              v-model="width"
              :label="$t('admin.pricing_widget.settings.width')"
              v-test="'pricing-widget-width'"
            />
            <BaseInput
              v-model="height"
              :label="$t('admin.pricing_widget.settings.height')"
              v-test="'pricing-widget-height'"
            />
            <div>
              <BaseInputLabel
                :text="$t('admin.pricing_widget.settings.color')"
              />
              <BaseColorLabel
                v-model="color"
                large
                colorPicker
                v-test="'pricing-widget-color'"
              />
            </div>
          </div>
          <Flex justify="end">
            <BaseButton submitForm v-test="'pricing-widget-save'">
              {{ $t('global.actions.save') }}
            </BaseButton>
          </Flex>
        </BaseForm>
      </BaseCard>
      <BaseCard :mb="2" :heading="$t('global.widget_code')">
        <BaseText mb>
          {{ $t('admin.pricing_widget.heading_snippet') }}:
        </BaseText>
        <BaseCodeField :code="snippetCode" v-test="'pricing-widget-snippet'" />
      </BaseCard>
      <BaseCard :heading="$t('global.preview')">
        <iframe
          :class="$style.iframe"
          :src="widgetUrl"
          v-test="'pricing-widget-preview'"
        />
      </BaseCard>
    </PageWrap>
  </ModuleBase>
</template>

<script setup lang="ts">
import ModuleBase from '@/modules/ModuleBase.vue';
import PageWrap from '../PageWrap.vue';
import { useStorage } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useI18n } from 'vue-i18n';
import { flash } from '@/helpers/ui';

const width = useStorage('pricing_widget_width', 400);
const height = useStorage('pricing_widget_height', 600);
const color = ref('');
const widgetUrl = ref('');

const { onResult, loading } = useQuery(gql`
  query getMicroSite {
    microSite {
      baseColor
      url
    }
  }
`);

onResult(({ data: { microSite } }) => {
  color.value = microSite.baseColor;
  widgetUrl.value = microSite.url + '/services?layout=embed';
});

const snippetCode = computed(
  () =>
    `<iframe src="${widgetUrl.value}" style="width: 100%; max-width: ${width.value}px; height: ${height.value}px; border: none;"></iframe>`
);

const { mutate, onDone } = useMutation(gql`
  mutation updateMicroSite($input: UpdateMicroSiteInput!) {
    updateMicroSite(input: $input) {
      microSite {
        id
      }
    }
  }
`);

const { t } = useI18n();
onDone(() => {
  flash(t('global.flash.settings_saved'));
});
</script>

<style lang="scss" module>
.inputs {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-bottom: $spacing;

    &:not(:last-child) {
      margin-right: $spacing;
    }
  }
}

.iframe {
  border: 0;
  width: 100%;
  height: 600px;
}
</style>
