<template>
  <BaseModal
    small
    :heading="$t('roster.edit_roster')"
    useForm
    @submit="submit"
    @close="$emit('close')"
  >
    <ConfirmRecurringUpdate
      v-if="showRecurringConfirmation"
      @submitAll="save('all')"
      @submitSingle="save('single')"
      @close="showRecurringConfirmation = false"
    />
    <div :class="$style.inner">
      <div v-for="(time, index) in times" :key="index" :class="$style.times">
        <BaseTimePicker
          v-model="time.open"
          :maxValue="time.closed"
          :minValue="index > 0 ? times[index - 1].closed : undefined"
          v-test="'roster-entry-start'"
        />
        -
        <BaseTimePicker
          v-model="time.closed"
          :minValue="minValue(time.open)"
          v-test="'roster-entry-end'"
        />
        <BaseIcon
          v-if="times.length > 1"
          name="delete"
          clickable
          @click="times.splice(index, 1)"
          v-test="'roster-entry-time-delete'"
        />
      </div>
    </div>
    <Flex justify="center" mt>
      <BaseText
        iconBefore="plus"
        link
        @click="addTimes"
        v-test="'roster-entry-add'"
      >
        {{ $t('roster.add_times') }}
      </BaseText>
    </Flex>
    <Flex mt wrap>
      <BaseDropdown
        v-model="recurring"
        :disabled="!!formData.recurring"
        :label="$t('appointment.recurring.repeat_label')"
        :options="[
          {
            label: $t('global.disabled'),
            value: null
          },
          {
            label: $t('appointment.recurring.frequency_options.weekly'),
            value: 'weekly'
          },
          {
            label: $t('global.biweekly'),
            value: 'biweekly'
          }
        ]"
        v-test="'roster-entry-repeat'"
      />
      <BaseRadio
        v-if="recurring"
        v-model="recurringEnds"
        :label="$t('appointment.recurring.ends_label')"
        :options="[
          {
            label: $t('appointment.recurring.ends_options.never'),
            value: false
          },
          {
            label: $t('appointment.recurring.ends_options.date'),
            value: true
          }
        ]"
        v-test="'roster-entry-repeat-end'"
      />
      <BaseDatePicker
        v-if="recurringEnds"
        v-model="recurringEndDate"
        :label="$t('global.end_date')"
        :minValue="entry.date"
        v-test="'roster-entry-repeat-end-date'"
      />
    </Flex>
    <BaseAlert
      v-if="isOutsideOpeningHours"
      :text="$t('roster.outside_company_hours_long')"
      color="warning"
      mt
    />
    <BaseText v-if="errorMessage" color="error" mt>
      {{ errorMessage }}
    </BaseText>
    <template #footerSub>
      <BaseText
        v-if="!creatingNew && !isOverride"
        color="error"
        iconBefore="delete"
        link
        inline
        @click="
          () => {
            formData.state = 'blank';
            submit();
          }
        "
        v-test="'roster-entry-delete'"
      >
        {{ $t('global.actions.delete') }}
      </BaseText>
      <BaseText
        v-if="isOverride"
        link
        @click="
          () => {
            formData.state = 'recurring';
            save();
          }
        "
      >
        {{ $t('global.actions.restore') }}
      </BaseText>
    </template>
    <template #footer>
      <BaseButton submitForm :loading="saving" v-test="'roster-entry-submit'">
        {{ $t('global.actions.submit') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { useLocationsStore } from '@/stores/locations';
import type { Entry } from './types';
import axios from '@/axios';
import dayjs from 'dayjs';
import ConfirmRecurringUpdate from './ConfirmRecurringUpdate.vue';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { checkIsOutsideOpeningHours } from './helpers';

const props = defineProps<{
  entry: Entry;
}>();

const emit = defineEmits(['close']);
const entries = inject<Ref<Entry[]>>('entries');

const formData = {
  ...props.entry
};

const times = ref([...formData.times.map((time) => ({ ...time }))]);

const addTimes = () => {
  const lastTime = times.value[times.value.length - 1];

  times.value.push({
    open: lastTime.closed, // Set the opening hours of the new time to the closing hours of the previous time
    closed: String(parseInt(lastTime.closed.slice(0, 2)) + 1) + ':00' // Add one hour to the starting time
  });
};

const creatingNew = ref(false);
const isOverride = ref(props.entry.state === 'override_single');

if (!times.value.length) {
  times.value = [
    {
      open: '10:00',
      closed: '18:00'
    }
  ];

  formData.state = 'single';
  creatingNew.value = true;
}

const isOutsideOpeningHours = computed(() => {
  if (props.entry.type === 'location') {
    return false;
  } else {
    return checkIsOutsideOpeningHours(props.entry, times);
  }
});

const recurring = ref(formData.recurring);
const recurringEndDate = ref(
  formData.recurring_end_date ||
    dayjs(props.entry.date).add(1, 'week').format('YYYY-MM-DD')
);
const recurringEnds = ref(!!formData.recurring_end_date);

const saving = ref(false);

if (!formData.location_id) {
  const { locationId } = useLocationsStore();
  formData.location_id = locationId;
}

const minValue = (timeString: string) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  return dayjs().hour(hours).minute(minutes).add(5, 'minute').format('HH:mm');
};

const showRecurringConfirmation = ref(false);

const submit = () => {
  errorMessage.value = '';

  if (recurring.value) {
    if (creatingNew.value || !formData.recurring) {
      save();
    } else {
      showRecurringConfirmation.value = true;
    }
  } else {
    save();
  }
};

const errorMessage = ref('');
const { t } = useI18n();

const save = (recurringState?: 'all' | 'single') => {
  saving.value = true;
  showRecurringConfirmation.value = false;

  formData.times = times.value;
  formData.recurring = recurring.value;

  formData.recurring_end_date =
    recurring.value && recurringEnds.value ? recurringEndDate.value : null;
  formData.recurring_start_date = recurring.value ? formData.date : null;

  if (recurringState === 'single') {
    formData.state =
      formData.state === 'blank' ? 'override_blank' : 'override_single';
  }

  if (
    !recurringState &&
    formData.state !== 'blank' &&
    formData.state !== 'override_blank' &&
    formData.state !== 'override_single' &&
    !isOverride.value
  ) {
    formData.state = recurring.value ? 'recurring' : 'single';
  }

  axios
    .put(`/roster_entries/${props.entry.id}`, formData)
    .then(({ data }) => {
      const existingEntry = entries?.value.find(
        (entry) => entry.id === props.entry.id
      );
      if (existingEntry) {
        Object.keys(data.roster_entry).forEach((key) => {
          existingEntry[key] = data.roster_entry[key];
        });
      }

      flash(t('roster.completed'));
      emit('close');
    })
    .catch(({ errors: { errors } }) => {
      if (errors.length) {
        errorMessage.value = errors[0].detail;
      }
    })
    .finally(() => {
      saving.value = false;
    });
};
</script>

<style lang="scss" module>
.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $spacing;
}

.times {
  display: flex;
  align-items: center;
  gap: $spacing;
}
</style>
