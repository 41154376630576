<template>
  <div v-if="treatwellSignup" :class="$style.treatwell" />
  <div v-else :class="$style.base" />
</template>

<script setup lang="ts">
import { useCompanyStore } from '@/stores/company';
import { storeToRefs } from 'pinia';
const { treatwellSignup } = storeToRefs(useCompanyStore());
</script>

<style lang="scss" module>
.base {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #aeaee9;
}

.treatwell {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: url('/img/treatwell/bg.png') center center no-repeat;
  background-size: cover;
}
</style>
