import type { Service } from '@/types';
import { useRoute } from 'vue-router';

import { useLeaveConfirmation } from '@/helpers/routing';
import { useResourcesStore } from '@/stores/resources';
import { useLocationsStore } from '@/stores/locations';

import type {
  DayOption,
  ServiceVariation,
  ServiceVariationsFormData,
  RequirementAttributes
} from './types';
import { DaysOfWeek } from '@/types';
import { useServiceGroupsStore } from '@/stores/service-groups';

const daysOfWeek = [
  DaysOfWeek.Mo,
  DaysOfWeek.Tu,
  DaysOfWeek.We,
  DaysOfWeek.Th,
  DaysOfWeek.Fr,
  DaysOfWeek.Sa,
  DaysOfWeek.Su
];

const getServiceOfferingsDefaults = (): DayOption[] =>
  daysOfWeek.map((dayOfWeek) => ({
    available: true,
    dayOfWeek,
    endTime: '',
    max: 0,
    startTime: ''
  }));

const formatSlotsData = (slots: DayOption[]) =>
  getServiceOfferingsDefaults().map((option) => {
    const slot = slots.find(
      (slot: DayOption) => slot.dayOfWeek === option.dayOfWeek
    );

    return {
      available: !!slot,
      dayOfWeek: slot?.dayOfWeek || option.dayOfWeek,
      endTime: slot?.endTime || option.endTime,
      max: slot?.max || option.max,
      startTime: slot?.startTime || option.startTime
    };
  });

export const useServiceVariations = (id?: string) => {
  const route = useRoute();
  const isLoading = ref(false);
  const { locationIds } = useLocationsStore();

  const { resourcesByType } = useResourcesStore();
  const resources = resourcesByType('employee');
  const resourceIds = resources.map((resource) => resource.id);
  const {
    getServiceGroupById,
    createServiceGroup: storeCreateServiceGroup,
    updateServiceGroup: storeUpdateServiceGroup
  } = useServiceGroupsStore();

  const requirementsPerVariation = ref(false);

  const formData: ServiceVariationsFormData = reactive({
    bookable: true,
    bookableLocationIds: locationIds,
    categoryId: route.params.categoryId
      ? parseInt(route.params.categoryId as string)
      : 0,
    description: '',
    extraInformation: '',
    medical: false,
    name: '',
    serviceOfferingEnabled: false,
    serviceOfferingAttributes: {
      offeringType: 'WEEKDAYS',
      payload: {
        slots: getServiceOfferingsDefaults()
      }
    },
    requirementsAttributes: [
      {
        _uid: 1,
        destroy: false,
        primary: true,
        resourceIds,
        type: 'EMPLOYEE'
      }
    ],
    rwgName: '',
    treatmentCode: '',
    vatRateId: null,
    variations: [
      {
        variationName: '',
        buffer: 0,
        duration: 60,
        durationFinish: 0,
        durationProcessing: 0,
        durationSetup: 0,
        requiresProcessingTime: false,
        price: 0,
        variationGroupSortOrder: 0,
        twTreatmentIds: [],
        twTreatments: [],
        requirementsAttributes: [
          {
            _uid: 1,
            destroy: false,
            primary: true,
            resourceIds,
            type: 'EMPLOYEE'
          }
        ]
      }
    ]
  });

  const checkAllRequirementsSameLength = () => {
    // if any variation has different requirements than the first one means we have different requirements
    for (let i = 1; i < formData.variations.length; i++) {
      if (
        formData?.variations[i - 1].requirementsAttributes?.length !==
        formData?.variations[i].requirementsAttributes?.length
      ) {
        requirementsPerVariation.value = true;
        return true;
      }
    }
  };

  const checkRequirementAreTheSame = (
    req1: RequirementAttributes,
    req2: RequirementAttributes
  ) => {
    let result = true;
    if (req1.resourceIds.length !== req2.resourceIds.length) {
      return false;
    }
    req1.resourceIds.forEach((resourceId) => {
      if (!req2.resourceIds.find((r) => r === resourceId)) {
        result = false;
      }
    });

    return result;
  };

  const checkIfHasDifferentRequirements = () => {
    // one variation we can skip the check
    if (formData.variations.length === 1) {
      requirementsPerVariation.value = false;
      return;
    }

    if (checkAllRequirementsSameLength()) {
      return;
    }

    formData.variations.forEach((variation, variationIndex) => {
      variation.requirementsAttributes?.forEach((requirement) => {
        formData.variations.forEach(
          (variationToCheck, variationToCheckIndex) => {
            if (variationIndex !== variationToCheckIndex) {
              let foundEqualRequirement = false;
              variationToCheck.requirementsAttributes?.forEach(
                (requirementToCheck) => {
                  if (
                    checkRequirementAreTheSame(requirement, requirementToCheck)
                  ) {
                    foundEqualRequirement = true;
                  }
                }
              );
              if (!foundEqualRequirement) {
                requirementsPerVariation.value = true;
              }
            }
          }
        );
      });
    });
  };

  const { resetConfirmation } = useLeaveConfirmation(formData);

  const onlineBookableError = computed(
    () => !formData.bookableLocationIds?.length && formData.bookable
  );

  nextTick(() => {
    resetConfirmation();
  });

  if (id) {
    const serviceGroup = getServiceGroupById(id);
    if (serviceGroup) {
      formData.id = serviceGroup.id;

      Object.keys(formData).forEach((key) => {
        const firstService = serviceGroup.services[0];
        if (firstService[key] !== undefined) {
          formData[key] = firstService[key];
        }

        if (serviceGroup[key] !== undefined) {
          formData[key] = serviceGroup[key];
        }
      });

      formData.requirementsAttributes =
        serviceGroup.services[0].requirements.map((req) => ({
          primary: req.primary,
          resourceIds: req.resources.map((r) => r.id),
          type: req.type,
          id: req.id
        }));

      formData.serviceOfferingEnabled =
        serviceGroup.services[0].offeringEnabled;

      const slots =
        serviceGroup.services[0]?.serviceOffering?.payload?.slots || [];
      formData.serviceOfferingAttributes.payload.slots = formatSlotsData(slots);

      formData.variations = serviceGroup.services.map((service: Service) => ({
        id: service.id,
        variationName: service.variationName,
        buffer: service.buffer,
        duration: service.duration,
        durationFinish: service.durationFinish,
        durationProcessing: service.durationProcessing,
        durationSetup: service.durationSetup,
        requiresProcessingTime: service.requiresProcessingTime,
        price: service.price,
        variationGroupSortOrder: service.variationGroupSortOrder,
        treatmentCode: service.treatmentCode,
        twTreatmentIds: service.twTreatmentIds,
        twTreatments: service.twTreatments,
        requirementsAttributes: service.requirements.map((req) => ({
          primary: req.primary,
          resourceIds: req.resources.map((r) => r.id),
          type: req.type,
          groupId: req.groupId,
          id: req.id
        }))
      }));

      formData.categoryId = serviceGroup.category.id;

      checkIfHasDifferentRequirements();

      nextTick(() => {
        resetConfirmation();
      });
    }
  }

  const getCleanedUpData = () => {
    const cleanData = { ...formData };
    cleanData.requirementsAttributes = cleanData.requirementsAttributes.map(
      (r) => ({
        ...r,
        _uid: undefined
      })
    );

    // if we have only one variation, its name should be the same as the group name
    if (cleanData.variations.filter((v) => !v.destroy).length === 1) {
      const firstVariationIndex = cleanData.variations.findIndex(
        (v) => !v.destroy
      );
      cleanData.variations[firstVariationIndex].variationName = cleanData.name;
    }

    /*   This is the service offering setup cleanup.
     *   If we have default setting there we want to disable it
     *   for backend performance reasons
     */
    const serviceOfferingSet =
      !!cleanData.serviceOfferingAttributes.payload.slots.find(
        (option) =>
          option.max || option.startTime || option.endTime || !option.available
      );
    if (!serviceOfferingSet) {
      cleanData.serviceOfferingEnabled = false;
    }

    if (cleanData.serviceOfferingAttributes) {
      cleanData.serviceOfferingAttributes.payload.slots =
        cleanData.serviceOfferingAttributes.payload.slots
          .filter((slot) => slot.available)
          .map((slot) => {
            const { dayOfWeek, endTime, startTime, max } = slot;
            return { dayOfWeek, endTime, startTime, max };
          });
    }

    return cleanData;
  };

  const getRequirementsForService = (variation: ServiceVariation) => {
    if (requirementsPerVariation.value) {
      return variation.requirementsAttributes?.map((r) => ({
        ...r,
        _uid: undefined
      }));
    } else {
      const generalPrimary = formData.requirementsAttributes.find(
        (r) => r.primary
      );

      const variationPrimary = variation.requirementsAttributes?.find(
        (r) => r.primary
      );

      const generalToCopy = formData.requirementsAttributes
        .filter((r) => !r.primary)
        .map((r) => ({
          ...r,
          id: undefined,
          _uid: undefined
        }));

      const requirementsToDelete =
        variation.requirementsAttributes
          ?.filter((r) => !r.primary)
          .map((r) => ({
            ...r,
            destroy: true,
            _uid: undefined
          })) || [];

      return [
        {
          ...generalPrimary,
          id: variationPrimary?.id,
          _uid: undefined
        },
        ...generalToCopy,
        ...requirementsToDelete
      ];
    }
  };

  const formatDataToServiceGroup = () => {
    const cleanData = getCleanedUpData();
    return {
      name: cleanData.name,
      servicesAttributes: cleanData.variations.map((variation) => ({
        destroy: variation.destroy,
        id: variation.id,
        bookable: cleanData.bookable,
        bookableLocationIds: cleanData.bookableLocationIds,
        categoryId: cleanData.categoryId,
        description: cleanData.description,
        buffer: variation.buffer,
        duration: variation.duration,
        durationSetup: variation.durationSetup,
        durationFinish: variation.durationFinish,
        durationProcessing: variation.durationProcessing,
        requiresProcessingTime: variation.requiresProcessingTime,
        extraInformation: cleanData.extraInformation,
        medical: cleanData.medical,
        name: variation.variationName,
        variationGroupSortOrder: variation.variationGroupSortOrder,
        serviceOfferingEnabled: cleanData.serviceOfferingEnabled,
        price: variation.price,
        requirementsAttributes: getRequirementsForService(variation),
        treatmentCode: cleanData.treatmentCode,
        twTreatmentIds: variation.twTreatmentIds,
        servicesTreatwellTreatmentsAttributes: !variation.id
          ? variation.twTreatments?.map(({ treatwellTreatmentId }) => ({
              treatwellTreatmentId
            }))
          : undefined,
        rwgName: cleanData.rwgName,
        serviceOfferingAttributes: cleanData.serviceOfferingAttributes,
        vatRateId: cleanData.vatRateId
      }))
    };
  };

  const createServiceGroup = () => {
    const createServiceGroupInput = formatDataToServiceGroup();
    return storeCreateServiceGroup(createServiceGroupInput);
  };

  const updateServiceGroup = () => {
    const updateServiceGroupInput = {
      ...formatDataToServiceGroup(),
      id
    };
    return storeUpdateServiceGroup(updateServiceGroupInput);
  };

  return {
    isLoading,
    formData,
    onlineBookableError,
    requirementsPerVariation,
    resetConfirmation,
    createServiceGroup,
    updateServiceGroup
  };
};
