<template>
  <div :class="[$style.base, { [$style.isSmall]: $screen === 's' }]">
    <BaseSpinner v-if="loading" />
    <BaseForm v-else @submit="submit">
      <BaseCard large>
        <BaseHeading mb>
          {{ $t('customers.personal_info') }}
        </BaseHeading>
        <BaseGrid container mb>
          <BaseGrid :size="6">
            <BaseInput
              v-model="customer.firstName"
              focus
              :label="$t('global.first_name')"
              required
              mb
              v-test="'customer-firstName'"
            />
          </BaseGrid>
          <BaseGrid :size="6">
            <BaseInput
              v-model="customer.lastName"
              :label="$t('global.last_name')"
              required
              mb
              v-test="'customer-lastName'"
            />
          </BaseGrid>
          <BaseGrid :size="4" :mSize="6">
            <BaseRadio
              v-model="customer.gender"
              inline
              :label="$t('global.gender')"
              :options="[
                {
                  label: $t('global.male'),
                  value: 'MALE'
                },
                {
                  label: $t('global.female'),
                  value: 'FEMALE'
                },
                {
                  label: $t('global.nonbinary'),
                  value: 'NONBINARY'
                },
                {
                  label: $t('global.unknown'),
                  value: undefined
                }
              ]"
              v-test="'customer-gender'"
            />
          </BaseGrid>
          <BaseGrid :size="2" :mSize="6">
            <BaseInput
              v-model="customer.dateOfBirth"
              :label="$t('global.date_of_birth')"
              :placeholder="$t('global.manual_date')"
              type="date"
            />
          </BaseGrid>
          <BaseGrid v-if="company.medical" :size="6">
            <BaseInput
              v-model="customer.personalRegistrationNumber"
              :label="$t('global.personal_registration_number')"
              mb
              v-test="'customer-personal-registration-number'"
            />
          </BaseGrid>
        </BaseGrid>
        <BaseHeading mb>
          {{ $t('customers.contact_info') }}
        </BaseHeading>
        <BaseGrid container mb>
          <BaseGrid :size="12">
            <BaseInput
              v-model="customer.email"
              :label="$t('global.email_address')"
              :error="
                emailInvalid
                  ? $t('global.validations.invalid', {
                      value: $t('global.email_address')
                    })
                  : undefined
              "
              type="email"
              mb
              v-test="'customer-email'"
            />
          </BaseGrid>
          <BaseGrid :size="6">
            <BaseInput
              v-model="customer.phone"
              :label="$t('global.phone_number')"
              type="tel"
              :maxLength="20"
              mb
              v-test="'customer-phone'"
            />
          </BaseGrid>
          <BaseGrid :size="6">
            <BaseInput
              v-model="customer.mobilePhone"
              :label="$t('global.mobile_number')"
              type="tel"
              :maxLength="20"
              mb
              v-test="'customer-mobilePhone'"
            />
          </BaseGrid>
          <BaseGrid :size="6">
            <BaseInput
              v-model="customer.address"
              :label="$t('global.address')"
              mb
              v-test="'customer-address'"
            />
          </BaseGrid>
          <BaseGrid :size="2">
            <BaseInput
              v-model="customer.zipcode"
              :label="$t('global.postal_code')"
              mb
              v-test="'customer-zipcode'"
            />
          </BaseGrid>
          <BaseGrid :size="4">
            <BaseInput
              v-model="customer.city"
              :label="$t('global.city')"
              mb
              v-test="'customer-city'"
            />
          </BaseGrid>
        </BaseGrid>
        <CustomerTags v-model="customerTagIds" :mb="2" />
        <BaseHeading mb>
          {{ $t('global.custom_fields') }}
        </BaseHeading>
        <BaseText mb>
          {{ $t('customers.add_custom_fields') }}
          <BaseText inline :routerLink="{ name: 'admin-customers' }">
            {{ $t('customers.customer_settings') }}
          </BaseText>
        </BaseText>
        <BaseGrid container mb>
          <BaseGrid v-if="companySettings.customers.custom1Label" :size="6">
            <BaseInput
              v-model="customer.custom1"
              :label="companySettings.customers.custom1Label"
              mb
              v-test="'customer-custom1'"
            />
          </BaseGrid>
          <BaseGrid v-if="companySettings.customers.custom2Label" :size="6">
            <BaseInput
              v-model="customer.custom2"
              :label="companySettings.customers.custom2Label"
              mb
              v-test="'customer-custom2'"
            />
          </BaseGrid>
          <BaseGrid v-if="companySettings.customers.custom3Label" :size="6">
            <BaseInput
              v-model="customer.custom3"
              :label="companySettings.customers.custom3Label"
              mb
              v-test="'customer-custom3'"
            />
          </BaseGrid>
          <BaseGrid v-if="companySettings.customers.custom4Label" :size="6">
            <BaseInput
              v-model="customer.custom4"
              :label="companySettings.customers.custom4Label"
              mb
              v-test="'customer-custom4'"
            />
          </BaseGrid>
          <BaseGrid v-if="companySettings.customers.custom5Label" :size="6">
            <BaseInput
              v-model="customer.custom5"
              :label="companySettings.customers.custom5Label"
              mb
              v-test="'customer-custom5'"
            />
          </BaseGrid>
          <BaseGrid v-if="companySettings.customers.custom6Label" :size="6">
            <BaseInput
              v-model="customer.custom6"
              :label="companySettings.customers.custom6Label"
              mb
              v-test="'customer-custom6'"
            />
          </BaseGrid>
          <BaseGrid v-if="companySettings.customers.custom7Label" :size="6">
            <BaseInput
              v-model="customer.custom7"
              :label="companySettings.customers.custom7Label"
              mb
              v-test="'customer-custom7'"
            />
          </BaseGrid>
          <BaseGrid v-if="companySettings.customers.custom8Label" :size="6">
            <BaseInput
              v-model="customer.custom8"
              :label="companySettings.customers.custom8Label"
              mb
              v-test="'customer-custom8'"
            />
          </BaseGrid>
          <BaseGrid v-if="companySettings.customers.custom9Label" :size="6">
            <BaseInput
              v-model="customer.custom9"
              :label="companySettings.customers.custom9Label"
              mb
              v-test="'customer-custom9'"
            />
          </BaseGrid>
          <BaseGrid v-if="companySettings.customers.custom10Label" :size="6">
            <BaseInput
              v-model="customer.custom10"
              :label="companySettings.customers.custom10Label"
              mb
              v-test="'customer-custom10'"
            />
          </BaseGrid>
        </BaseGrid>

        <BaseHeading mb>
          {{ $t('customers.additional_info') }}
        </BaseHeading>

        <BaseGrid container mb>
          <BaseGrid :size="12">
            <BaseInput
              v-model="customer.customerNotes"
              :label="$t('customers.additional_customer_info')"
              :info="$t('customers.additional_customer_info_tooltip')"
              mb
              v-test="'customer-customerNotes'"
            />
          </BaseGrid>
          <BaseGrid :size="12">
            <BaseInput
              v-model="customer.invoiceNotes"
              :label="$t('customers.additional_invoice_info')"
              type="textarea"
              :info="$t('customers.additional_invoice_info_tooltip')"
              mb
              v-test="'customer-invoiceNotes'"
            />
          </BaseGrid>
          <BaseGrid :size="12">
            <BaseInput
              v-model="customer.alertNotes"
              :label="$t('customers.warning_saving_appointments')"
              :info="$t('customers.warning_saving_appointments_tooltip')"
              mb
              v-test="'customer-alertNotes'"
            />
          </BaseGrid>
        </BaseGrid>
        <BaseHeading mb>
          {{ $t('customers.customer_picture') }}
        </BaseHeading>
        <UploadControls v-model="customer.picture" preset="customers" />
        <BaseGrid container mt>
          <BaseGrid>
            <div :class="$style.footer">
              <BaseButton
                color="inverted"
                mr
                :routerLink="
                  customerId
                    ? {
                        name: 'customer-overview',
                        params: { customerId }
                      }
                    : {
                        name: 'customers'
                      }
                "
              >
                {{ $t('global.actions.cancel') }}
              </BaseButton>
              <BaseButton
                :loading="saving"
                submitForm
                v-test="'customer-submit'"
              >
                {{ $t('global.actions.save') }}
              </BaseButton>
            </div>
          </BaseGrid>
        </BaseGrid>
      </BaseCard>
    </BaseForm>
  </div>
</template>

<script lang="ts" setup>
import UploadControls from '@/modules/customers/components/UploadControls.vue';
import { useCompanyStore } from '@/stores/company';
import { GET_CUSTOMER, UPDATE_CUSTOMER, CREATE_CUSTOMER } from './graphql';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { useRoute, useRouter } from 'vue-router';
import { computed, inject, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { flash } from '@/helpers/ui';
import eventBus from '@/event-bus';
import CustomerTags from '../CustomerTags.vue';
import { ASSIGN_CUSTOMER_TAGS } from '../graphql';

const { company, companySettings } = useCompanyStore();
const route = useRoute();
const router = useRouter();
const mixpanel = inject<any>('mixpanel');
const { t } = useI18n();

const customer = reactive({
  address: '',
  alertNotes: '',
  blocked: false,
  city: '',
  custom1: '',
  custom10: '',
  custom2: '',
  custom3: '',
  custom4: '',
  custom5: '',
  custom6: '',
  custom7: '',
  custom8: '',
  custom9: '',
  customerNotes: '',
  dateOfBirth: null,
  email: '',
  firstName: '',
  gender: null,
  invoiceNotes: '',
  lastName: '',
  mobilePhone: '',
  personalRegistrationNumber: '',
  phone: '',
  picture: '',
  zipcode: ''
});

provide('customer', customer);

const loading = ref(false);
const customerTagIds = ref<number[]>([]);

const getCustomer = () => {
  loading.value = true;

  const { onResult } = useQuery(GET_CUSTOMER, {
    id: customerId.value
  });

  onResult(({ data }) => {
    Object.keys(customer).forEach((key) => {
      if (data.customer[key]) {
        customer[key] = data.customer[key];
      }
    });

    if (data.customer.customerTags) {
      customerTagIds.value = data.customer.customerTags.map(
        (tag: any) => tag.id
      );
    }

    loading.value = false;
  });
};

const customerId = computed(() =>
  route && typeof route.params.id === 'string'
    ? parseInt(route.params.id)
    : null
);

if (customerId.value) {
  getCustomer();
}

const saving = ref(false);
const emailInvalid = ref(false);

const submit = () => {
  saving.value = true;
  emailInvalid.value = false;

  if (!customer.dateOfBirth) {
    // When setting a date and then clearing it, the value becomes an empty string
    // But the backend doesn't like empty strings for the DateTime type
    // We should ideally fix this in the backend
    customer.dateOfBirth = null;
  }

  if (customerId.value) {
    const { mutate } = useMutation(UPDATE_CUSTOMER);
    mutate({
      input: {
        id: customerId.value,
        customerAttributes: customer
      }
    }).then(() => {
      updateTags(customerId.value).then(() => {
        flash(t('global.flash.customer_updated'));

        router.push({
          name: 'customer-overview',
          params: {
            customerId: customerId.value
          }
        });
      });
    });
  } else {
    const { mutate } = useMutation(CREATE_CUSTOMER);
    mutate({
      input: {
        customerAttributes: customer
      }
    }).then(
      ({
        data: {
          createCustomer: { customer, errors }
        }
      }) => {
        if (errors) {
          saving.value = false;
          if (errors.email?.length) {
            emailInvalid.value = true;
          }
          return;
        } else {
          updateTags(customer.id).then(() => {
            flash(t('global.flash.customer_created'));
            mixpanel.track('Customer created');
            eventBus.$emit('customer-created');

            router.push({
              name: 'customer-overview',
              params: {
                customerId: customer.id
              }
            });
          });
        }
      }
    );
  }
};

const updateTags = (customerId: number) => {
  const { mutate } = useMutation(ASSIGN_CUSTOMER_TAGS);

  return mutate({
    input: {
      customerId,
      tagIds: customerTagIds.value
    }
  });
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  justify-content: center;
}

.flex {
  display: flex;
  align-items: center;
}

.footer {
  display: flex;
  justify-content: flex-end;
}
</style>
