<template>
  <VariablesModal :variables="variables" @close="$emit('close')" />
</template>

<script setup lang="ts">
import VariablesModal from '@/modules/_shared/VariablesModal.vue';
import { useCompanyStore } from '@/stores/company';

defineEmits(['close']);

const { multiLocation } = useCompanyStore();

const variables = [
  'id',
  'salutation',
  'salutation_short',
  'first_name',
  'last_name',
  'birthday',
  'address',
  'phone',
  'postalcode',
  'city',
  'custom1',
  'custom2',
  'custom3',
  'custom4',
  'custom5',
  'custom6',
  'custom7',
  'custom8',
  'custom9',
  'custom10'
];

if (multiLocation) {
  variables.push('company_bank_account');
}
</script>
