<template>
  <div :class="$style.base">
    <BaseCard gray>
      <BaseRadio
        v-model="shareSetting"
        :label="$t('giftcard_widget.settings.share')"
        :options="[
          {
            value: ShareSettingsType.LINK,
            label: $t('promote.booking_widget.link_view')
          },
          {
            value: ShareSettingsType.BUTTON,
            label: $t('promote.booking_widget.button_view')
          },
          {
            value: ShareSettingsType.WIDGET,
            label: $t('promote.booking_widget.widget_view')
          }
        ]"
        v-test="'giftcard-setting-shareMethod'"
      />

      <div v-if="shareSetting === ShareSettingsType.WIDGET">
        <BaseRadio
          v-model="formData.settings.bookingWidget.autoWidth"
          :label="$t('promote.booking_widget.settings.widget.size')"
          mb
          mt
          :options="widthSizeOptions"
          v-test="'booking-settings-autoWidth'"
        />
        <BaseGrid container>
          <BaseGrid
            :size="formData.settings.bookingWidget.autoWidth ? 12 : 6"
            :sSize="12"
          >
            <BaseInput
              v-model="formData.settings.bookingWidget.height"
              :label="$t('promote.booking_widget.settings.widget.height')"
              type="number"
            />
          </BaseGrid>
          <BaseGrid
            v-if="!formData.settings.bookingWidget.autoWidth"
            :size="6"
            :sSize="12"
          >
            <BaseInput
              v-model="formData.settings.bookingWidget.width"
              :label="$t('promote.booking_widget.settings.widget.width')"
              type="number"
            />
          </BaseGrid>
        </BaseGrid>
      </div>
      <div v-else-if="shareSetting === ShareSettingsType.BUTTON">
        <BaseRadio
          v-model="formData.settings.bookingWidget.position"
          :label="$t('promote.booking_widget.settings.button.position')"
          :options="buttonPositionOptions"
          mt
          v-test="'giftcard-setting-buttonPosition'"
        />
      </div>
    </BaseCard>

    <BaseCard v-if="shareSetting === ShareSettingsType.LINK" gray>
      <BaseInputLabel :text="$t('promote.booking_widget.link')" />
      <BaseCodeField
        :code="widgetUrl"
        :loading="loadingMicroSite"
        buttonType="inverted"
        multiline
        v-test="'widget-styling-link'"
      />
    </BaseCard>
    <Snippet v-if="shareSetting !== ShareSettingsType.LINK" gray />
  </div>
</template>

<script lang="ts">
export default {
  name: 'SharingSettings'
};
</script>

<script setup lang="ts">
import { useBookingSettingsOptions } from '@/modules/admin/online-bookings/input-options';
import Snippet from './Snippet.vue';
import { useStorage } from '@vueuse/core';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const formData = inject<any>('giftcardSettingsData');

const { buttonPositionOptions, widthSizeOptions } = useBookingSettingsOptions();

enum ShareSettingsType {
  LINK = 'link',
  BUTTON = 'button',
  WIDGET = 'widget'
}

const shareSettings = Object.freeze(ShareSettingsType);

const shareSetting = useStorage('share-setting', shareSettings.LINK);
watch(shareSetting, (newValue) => {
  formData.settings.bookingWidget.inline = newValue !== shareSettings.BUTTON;
});

if (!formData.settings.bookingWidget.inline) {
  shareSetting.value = shareSettings.BUTTON;
}

const microSiteQuery = gql`
  query getMicroSite {
    microSite {
      id
      url
    }
  }
`;

const { result, loading: loadingMicroSite } = useQuery(microSiteQuery);

const widgetUrl = computed(() => {
  const microSiteUrl = result.value?.microSite.url || '';
  return microSiteUrl ? `${microSiteUrl}/widget_giftcards/new` : '';
});
</script>

<style lang="scss" module>
.base {
  & > *:not(:last-child) {
    margin-bottom: $spacing * 1.5;
  }
}
</style>
