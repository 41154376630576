type EnvironmentName =
  | 'production'
  | 'staging'
  | 'acceptance'
  | 'development'
  | 'test';

const locales = ['en', 'nl', 'de', 'pt', 'hr', 'es', 'fr'] as const;

interface Config {
  backendUrl?: string;
  bookingWidgetUrl?: string;
  cloudinaryCloudName?: string;
  datadogApplicationId?: string;
  datadogClientToken: string;
  datadogSampleRate: number;
  datadogServiceName: string;
  datadogReplaySampleRate?: number;
  datadogTraceSampleRate?: number;
  env: EnvironmentName;
  gtmId?: string;
  gtmUrl?: string;
  intercomId?: string;
  isDevelopment: boolean;
  isProduction: boolean;
  isStaging: boolean;
  isTest: boolean;
  locales: typeof locales;
  mixpanelToken?: string;
  stripeKey?: string;
  unleashKey?: string;
  unleashUrl?: string;
  wsUrl?: string;
}

const getConfig = (env: EnvironmentName): Config => {
  const defaults = {
    bookingWidgetUrl: undefined,
    datadogClientToken: 'pub6a5e2675a57e48e959e8869d36c9961d',
    datadogSampleRate: 35,
    datadogServiceName: 'salonized',
    env,
    isDevelopment: env === 'development',
    isProduction: env === 'production',
    isStaging: env === 'staging',
    isTest: env === 'test',
    locales
  };

  const datadogApplicationId = 'fb8cb671-4648-4a33-95b4-018544dcc821';

  switch (env) {
    case 'production':
      return {
        ...defaults,
        backendUrl: '',
        bookingWidgetUrl: 'https://widget.salonized.com',
        cloudinaryCloudName: 'salonized-eu-cld',
        datadogApplicationId,
        datadogReplaySampleRate: 0,
        // Backend traces that are automatically kept
        // Only keep a small portion, let DD decide the rest
        // using smart ingestion
        //
        // Because 99% of requests are on the calendar,
        // the ratio of traces is strongly biased towards the calendar
        datadogTraceSampleRate: 10,
        gtmId: 'GTM-59CF89',
        gtmUrl: 'https://analytics.salonized.com/gtm.js',
        intercomId: 'llecrrpz',
        mixpanelToken: 'f7e9ab2422cc9b7953604c2aabf7a30e',
        stripeKey: 'pk_live_7CLoj8Jn5PfrFEwKAm7UsIJJ',
        unleashKey:
          '*:production.e4779056c0eb8196a3dc19d460db12a37ee003dad3a68a0c2c247f1a',
        unleashUrl: 'https://unleash-edge.external.salonized.com/api/frontend',
        wsUrl: 'wss://api.salonized.com/cable'
      };
    case 'staging':
    case 'acceptance':
      return {
        ...defaults,
        backendUrl: '',
        bookingWidgetUrl: window.location.origin.replace('app', 'widget'),
        cloudinaryCloudName: 'salonized-testing',
        datadogApplicationId,
        datadogReplaySampleRate: 100,
        datadogTraceSampleRate: 100,
        gtmId: 'GTM-PL9MDMB',
        gtmUrl: 'https://analytics.salonized-staging.io/gtm.js',
        intercomId: 'f7f22yg8',
        mixpanelToken: 'b4c6574b1746732a48d66304e2a7da65',
        stripeKey: 'pk_test_gSWRSjlQPoLS6jg2YsLiIMLn',
        unleashKey:
          '*:development.0f84e565a142704a354ea9263f77b180e77c5932f332fb9bdaaf4ce7',
        unleashUrl: 'https://unleash-edge.salonized-staging.io/api/frontend',
        wsUrl: `${window.location.origin.replace('http://app', 'ws://api')}/cable`
      };
    case 'development':
      return {
        ...defaults,
        backendUrl: 'http://app.salonized.fml:3000',
        bookingWidgetUrl: 'http://localhost:8091',
        cloudinaryCloudName: 'salonized-testing',
        datadogReplaySampleRate: 100,
        datadogTraceSampleRate: 100,
        gtmId: '',
        gtmUrl: '',
        intercomId: 'f7f22yg8',
        mixpanelToken: 'a68c33fede0af794ea7231c8efa293e1',
        stripeKey: 'pk_test_gSWRSjlQPoLS6jg2YsLiIMLn',
        unleashKey:
          '*:development.0f84e565a142704a354ea9263f77b180e77c5932f332fb9bdaaf4ce7',
        unleashUrl: 'https://unleash-edge.salonized-staging.io/api/frontend',
        wsUrl: 'ws://app.salonized.fml:28080/cable'
      };
    case 'test':
      return {
        ...defaults,
        stripeKey: 'test-stripe-key',
        backendUrl: 'http://localhost:8090',
        bookingWidgetUrl: 'http://someurl', // Add a url to prevent loading the current page in iframes,
        unleashUrl: 'http://unleash.salonized.fml:8080',
        unleashKey: 'test-key'
      };
    default:
      return {
        ...defaults
      };
  }
};

export default Object.freeze(
  getConfig(window.Cypress ? 'test' : import.meta.env.VITE_ENV)
);
