<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.previewModeActive]: previewModeActive
      }
    ]"
  >
    <CreateCustomer
      v-if="showCreateCustomerModal"
      :modalData="customerData"
      @submit="onCustomerCreated"
      @close="showCreateCustomerModal = false"
    />
    <div :class="$style.header">
      <BaseHeading>
        {{ filters.capitalize($t('global.items.customer', 1)) }}
      </BaseHeading>
    </div>
    <div :class="$style.main">
      <div v-if="!formData.customerId">
        <CustomerSearch
          showCreate
          @select="setCustomerData($event)"
          v-test="'appointment-customer-search'"
        />
      </div>
      <div v-else :class="$style.customer">
        <CustomerDetails
          :options="['view', 'edit', 'remove']"
          :customer="customerData"
          :appointmentStartAt="formData.startAt"
          @select="onCustomerMoreClick"
        />
      </div>
    </div>
    <div v-if="!formData.customerId" :class="$style.addCustomer">
      <BaseText
        link
        inline
        @click="showCreateCustomerModal = true"
        v-test="'appointment-customer-new'"
      >
        + {{ $t('appointment.create.add_new_customer') }}
      </BaseText>
    </div>
  </div>
</template>

<script setup lang="ts">
import CustomerSearch from '@/components/CustomerSearch.vue';
import CustomerDetails from '@/components/customer-details/index.vue';
import CreateCustomer from '@/modals/CreateCustomer.vue';
import { ref } from 'vue';
import { useCreateAppointmentStore } from '@/stores/calendar-create-appointment';
import { useRouter } from 'vue-router';
import filters from '@/filters';
import { useCalendarPreviewStore } from '@/stores/calendar-preview';
import { storeToRefs } from 'pinia';

const showCreateCustomerModal = ref(false);
const { previewModeActive } = storeToRefs(useCalendarPreviewStore());

const { formData, customerData, setCustomerData } = useCreateAppointmentStore();
const router = useRouter();

const onCustomerCreated = (customer: any) => {
  setCustomerData(customer);
  showCreateCustomerModal.value = false;
};

const onCustomerMoreClick = (option: string) => {
  switch (option) {
    case 'edit':
      showCreateCustomerModal.value = true;
      break;
    case 'remove':
      setCustomerData();
      break;
    case 'view':
      router.push({
        name: 'customer-overview',
        params: { customerId: formData.customerId }
      });
      break;
  }
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.main {
  width: 100%;

  .base.previewModeActive & {
    order: 2;
  }
}

.customer {
  position: relative;

  .base:not(.smallScreen) & {
    margin: 0 $spacing * -1 $spacing * -1;
  }

  .base.smallScreen & {
    background-color: white;
    border-radius: $radius;
    border: 1px solid $color-border;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .base:not(.smallScreen) & {
    margin-bottom: $spacing * 0.5;
  }

  .base.smallScreen & {
    margin-bottom: $spacing;
  }
}

.addCustomer {
  .base:not(.previewModeActive) & {
    margin-top: $spacing;
  }

  .base.previewModeActive & {
    order: 1;
  }
}
</style>
